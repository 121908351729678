<template>
  <section>
    <b-card-body>
      <b-row>
        <b-col cols="12" class="mb-2">
          <h4>Pengajuan Pengeluaran Riil</h4>
          <p class="font-italic">
            Untuk pengajuan pengeluaran riil, silahkan siapkan kumpulan kwitansi
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>No / Tanggal SPPD</h6>
          <p class="card-text mb-25">
            {{ currentSppd.sppd_number || '-' }} /
            {{ currentSppd.letter_date | moment('DD MMMM YYYY') }}
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>{{ employee.type === 'pegawai' ? 'NIPPOS' : 'NIK' }} / Nama</h6>
          <p class="card-text mb-25">
            {{ employee.type === 'pegawai' ? employee.nippos : employee.nik }}
            / {{ employee.type === 'pegawai' ? employee.nama : employee.name }}
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>Jenis Fasilitas</h6>
          <p class="card-text mb-25">
            {{ sppdFacilityType }}
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>Jabatan</h6>
          <p class="card-text mb-25">
            {{
              employee.type === 'pegawai'
                ? employee.descjabatan
                : employee.position
            }}
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>Bagian/Unit Kerja</h6>
          <p class="card-text mb-25">
            {{ employee.namabagian || '-' }}
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>Kantor/Kota Tujuan</h6>
          <p class="card-text mb-25">{{ sppd.destination_office || '-' }}</p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>Tanggal Berangkat</h6>
          <p class="card-text mb-25">
            {{ sppd.departure_date | moment('DD MMMM YYYY') }}
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>Tanggal Kembali</h6>
          <p class="card-text mb-25">
            {{ sppd.return_date | moment('DD MMMM YYYY') }}
          </p>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-2"
          v-if="currentSppd.real_expense_step"
        >
          <h6>Status</h6>
          <p class="card-text mb-25">
            {{ currentSppd.status_real_expense_detail || '-' }}
          </p>
        </b-col>
      </b-row>
      <div>
        <div
          class="d-flex align-items-start justify-content-between mb-2"
          v-if="
            Object.keys(currentSppd).length && !currentSppd.real_expense_step
          "
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            class="mr-1"
            variant="outline-primary"
            @click="tambahPengeluaran"
          >
            <plus-icon size="1x" />
            <span> Tambah Pengeluaran</span>
          </b-button>
        </div>
        <b-table
          striped
          hover
          responsive
          show-empty
          :items="items"
          :fields="fields"
        >
          <template #cell(no)="data">{{ data.index + 1 }}</template>

          <template #cell(total_cost)="data">
            {{ data.item.total_cost | currency }}
          </template>

          <template #cell(status)="data">
            <span v-if="data.item.status === null">Menunggu Approval</span>
            <span v-else-if="data.item.status == 1" class="text-success">
              Disetujui
            </span>
            <span v-else class="text-danger">Ditolak</span>
          </template>

          <template #cell(receipt)="data">
            <b-button :href="data.item.file" variant="info" target="_blank">
              Cek Kwitansi
            </b-button>
          </template>

          <!-- A virtual column -->
          <template #cell(action)="data">
            <div style="min-width: 120px">
              <b-button
                v-if="data.item.status !== 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Edit'"
                variant="warning"
                size="sm"
                class="btn-icon mr-1"
                @click="gotoEditPage(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                v-if="data.item.status !== 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Delete'"
                variant="danger"
                size="sm"
                class="btn-icon"
                @click="confirmDelete(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
    </b-card-body>
    <b-card-footer align="right">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1"
        variant="outline-secondary"
        :to="{ name: 'manajemen-sppd' }"
      >
        Kembali
      </b-button>
      <b-button
        v-if="Object.keys(currentSppd).length && !currentSppd.real_expense_step"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        class="mr-1"
        variant="primary"
        @click="sendData"
      >
        {{ items.length ? 'Kirim' : 'Simpan' }}
      </b-button>
    </b-card-footer>
  </section>
</template>

<script>
import {
  BCardBody,
  BCardFooter,
  BRow,
  BCol,
  BButton,
  BTable,
  VBPopover,
} from 'bootstrap-vue'
import { PlusIcon } from 'vue-feather-icons'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  components: {
    BCardBody,
    BCardFooter,
    BRow,
    BCol,
    BButton,
    BTable,
    PlusIcon,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  props: {
    sppd: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const sppdId = this.$route.params.id

    return {
      sppdId,
      currentSppd: this.sppd,
      config: {
        api: '/real-expenses',
      },
      fields: [
        'no',
        { key: 'cost_component.name', label: 'Komponen Biaya' },
        { key: 'note', label: 'Catatan' },
        { key: 'total_cost', label: 'Biaya' },
        { key: 'status', label: 'Status' },
        { key: 'receipt', label: 'Kwitansi' },
      ],
      items: [],
    }
  },
  computed: {
    sppdFacilityType() {
      if (this.currentSppd.sppd_facility_type_id == 1) {
        return 'Perjalanan Dinas Biasa'
      }
      if (this.currentSppd.sppd_facility_type_id == 2) {
        return 'Perjalanan Dinas Fasilitas Khusus'
      }
      return '-'
    },
    employee() {
      return this.currentSppd.employee?.raw
        ? JSON.parse(this.currentSppd.employee.raw)
        : {}
    },
  },
  mounted() {
    const { query } = this.$route
    if (Object.keys(query).length) {
      if (query.event) {
        this.makeToast(query.event, query.title, query.text)
      }
      this.$router.replace({ query: null })
    }

    this.getData()
  },
  methods: {
    getData() {
      axios
        .get(`${this.config.api}/${this.$route.params.id}`)
        .then(res => {
          const { data } = res.data
          if (!data.real_expense_step) {
            this.fields.push({
              key: 'action',
              label: 'Aksi',
              thStyle: 'width: 120px',
            })
          }
          this.currentSppd = data
          this.items = data.real_expenses
        })
        .catch(err => console.error(err))
    },
    tambahPengeluaran() {
      this.$router.push({
        name: 'add-pengeluaran-rill',
        params: { sppdId: this.sppdId },
        query: { folder: this.currentSppd.folder },
      })
    },
    sendData() {
      let title = 'Catatan'
      let text =
        'Tidak mengisi biaya/kwitansi, dapat diartikan bahwa tidak ada biaya yang Anda keluarkan selama perjalanan dinas ini'

      if (this.items.length) {
        title = 'Perhatian'
        text =
          'Saya menyatakan dokumen yang diupload adalah benar/sah. Jika tidak benar, maka saya bersedia dikenakan sanksi/hukdis sesuai dengan ketentuan yang berlaku'
      }

      this.$swal({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, setuju',
        cancelButtonText: 'Tidak, batal',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-2',
        },
      }).then(result => {
        if (result.value) {
          axios
            .put(`/sppd/${this.sppdId}/real-expense/submit`)
            .then(() => {
              this.fields.pop()
              this.getData()
              this.makeToast(
                'success',
                'Update Pengeluaran Riil',
                'Pengeluaran riil berhasil dikirim',
              )
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Gagal mengirim data',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    gotoEditPage(id) {
      this.$router.push({
        name: 'edit-pengeluaran-riil',
        params: { sppdId: this.sppdId, sppdPengeluaranRiilId: id },
        query: { folder: this.currentSppd.folder },
      })
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(`${this.config.api}/${id}`)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Berhasil Dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.items = this.items.filter(item => item.id !== id)
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Tidak bisa di hapus',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
