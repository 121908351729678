var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":this.$route.meta.title}},[_c('b-tabs',{attrs:{"pills":"","content-class":"border border-1"}},[_c('b-tab',{attrs:{"lazy":"","title":"Detail Info"}},[_c('detail-info',{attrs:{"sppd":_vm.sppd},on:{"refresh-sppd":_vm.getData}})],1),(
            !Object.keys(_vm.sppd).length ||
            _vm.sppd.general_status === 'Sudah melakukan perjalanan'
          )?_c('b-tab',{attrs:{"lazy":"","active":_vm.activeTab == 'pengeluaran-riil',"title":"Pengeluaran Riil"}},[_c('pengeluaran-riil',{attrs:{"sppd":_vm.sppd}})],1):_vm._e(),(
            !Object.keys(_vm.sppd).length ||
            (_vm.sppd.general_status === 'Sudah melakukan perjalanan' &&
              _vm.sppd.transportation != 1)
          )?_c('b-tab',{attrs:{"lazy":"","active":_vm.activeTab == 'biaya-transportasi',"title":"Biaya Transportasi"}},[_c('biaya-transportasi',{attrs:{"sppd":_vm.sppd}})],1):_vm._e(),(
            !Object.keys(_vm.sppd).length ||
            (_vm.sppd.general_status === 'Sudah melakukan perjalanan' &&
              _vm.sppd.sppd_type_id != 3)
          )?_c('b-tab',{attrs:{"lazy":"","active":_vm.activeTab == 'biaya-akomodasi',"title":"Biaya Akomodasi"}},[_c('biaya-akomodasi',{attrs:{"sppd":_vm.sppd}})],1):_vm._e(),(
            !Object.keys(_vm.sppd).length ||
            (_vm.sppd.general_status === 'Sudah melakukan perjalanan' &&
              _vm.sppd.sppd_type_id == 2)
          )?_c('b-tab',{attrs:{"lazy":"","active":_vm.activeTab == 'pemeriksaan',"title":"Pemeriksaan"}},[_c('pemeriksaan',{attrs:{"sppd":_vm.sppd}})],1):_vm._e(),(
            !Object.keys(_vm.sppd).length ||
            (_vm.sppd.general_status === 'Sudah melakukan perjalanan' &&
              _vm.sppd.sppd_type_id == 3)
          )?_c('b-tab',{attrs:{"lazy":"","active":_vm.activeTab == 'pindah',"title":"Sumbangan Pindah"}},[_c('pindah',{attrs:{"sppd":_vm.sppd}})],1):_vm._e(),(
            !Object.keys(_vm.sppd).length ||
            _vm.sppd.general_status === 'Sudah melakukan perjalanan'
          )?_c('b-tab',{attrs:{"lazy":"","active":_vm.activeTab == 'bukti-bayar',"title":"Rekap Pengeluaran"}},[_c('bukti-bayar',{attrs:{"sppd":_vm.sppd}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }