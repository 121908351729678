<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-tabs pills content-class="border border-1">
          <b-tab lazy title="Detail Info">
            <detail-info :sppd="sppd" @refresh-sppd="getData" />
          </b-tab>
          <b-tab
            v-if="
              !Object.keys(sppd).length ||
              sppd.general_status === 'Sudah melakukan perjalanan'
            "
            lazy
            :active="activeTab == 'pengeluaran-riil'"
            title="Pengeluaran Riil"
          >
            <pengeluaran-riil :sppd="sppd" />
          </b-tab>
          <b-tab
            v-if="
              !Object.keys(sppd).length ||
              (sppd.general_status === 'Sudah melakukan perjalanan' &&
                sppd.transportation != 1)
            "
            lazy
            :active="activeTab == 'biaya-transportasi'"
            title="Biaya Transportasi"
          >
            <biaya-transportasi :sppd="sppd" />
          </b-tab>
          <b-tab
            v-if="
              !Object.keys(sppd).length ||
              (sppd.general_status === 'Sudah melakukan perjalanan' &&
                sppd.sppd_type_id != 3)
            "
            lazy
            :active="activeTab == 'biaya-akomodasi'"
            title="Biaya Akomodasi"
          >
            <biaya-akomodasi :sppd="sppd" />
          </b-tab> 
          <b-tab
            v-if="
              !Object.keys(sppd).length ||
              (sppd.general_status === 'Sudah melakukan perjalanan' &&
                sppd.sppd_type_id == 2)
            "
            lazy
            :active="activeTab == 'pemeriksaan'"
            title="Pemeriksaan"
          >
            <pemeriksaan :sppd="sppd" />
          </b-tab>
          <b-tab
            v-if="
              !Object.keys(sppd).length ||
              (sppd.general_status === 'Sudah melakukan perjalanan' &&
                sppd.sppd_type_id == 3)
            "
            lazy
            :active="activeTab == 'pindah'"
            title="Sumbangan Pindah"
          >
            <pindah :sppd="sppd" />
          </b-tab>
          <b-tab
            v-if="
              !Object.keys(sppd).length ||
              sppd.general_status === 'Sudah melakukan perjalanan'
            "
            lazy
            :active="activeTab == 'bukti-bayar'"
            title="Rekap Pengeluaran"
          >
            <bukti-bayar :sppd="sppd" />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BTabs, BTab } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'
import DetailInfo from './DetailInfo.vue'
import PengeluaranRiil from './pengeluaran-riil/Detail.vue'
import BiayaTransportasi from './biaya-transportasi/Detail.vue'
import BiayaAkomodasi from './biaya-akomodasi/Detail.vue'
import Pemeriksaan from './pemeriksaan/Detail.vue'
import Pindah from './sumbangan-pindah/Detail.vue'
import BuktiBayar from './BuktiBayar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    DetailInfo,
    PengeluaranRiil,
    BiayaTransportasi,
    BiayaAkomodasi,
    Pemeriksaan,
    Pindah,
    BuktiBayar,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },

  data() {
    return {
      config: {
        api: '/sppd',
        redirect: 'manajemen-sppd',
      },
      sppd: {},
      activeTab: this.$route.query.activeTab,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      axios
        .get(`${this.config.api}/detail_info/${this.$route.params.id}`)
        .then(res => {
          this.sppd = res.data.data
        })
    },
  },
}
</script>
